const defaultConfig = {
    maindir: "/theme/soon/javascript/vendor/hyphenopoly/",
    patterndir: "/theme/soon/javascript/vendor/hyphenopoly/patterns/",
    selectors: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    availableLanguages: {
        "en": "Supercalifragilisticexpialidocious",
        "de": "Silbentrennungsalgorithmus",
    },
    defaultSelectorConfig: {
        leftmin: 5,
        rightmin: 5,
        compound: "all"
    }
};
const config = window.hyphenopolyConfig ? Object.assign(Object.assign({}, defaultConfig), window.hyphenopolyConfig) : defaultConfig;
function getRequiredLanguage(defaultLang = 'en') {
    const htmlLang = document.documentElement.lang;
    const currentLang = config.availableLanguages[htmlLang] ? htmlLang : defaultLang;
    return {
        [currentLang]: config.availableLanguages[currentLang]
    };
}
export function configHyphenopoly() {
    window.Hyphenopoly = {
        require: getRequiredLanguage(),
        setup: {
            hide: "element",
            selectors: config.selectors.reduce((config, selector) => {
                config[selector] = defaultConfig.selectors;
                return config;
            }, {})
        },
        paths: {
            "maindir": config.maindir,
            "patterndir": config.patterndir
        }
    };
}
