<script lang="ts">
    import { onMount, onDestroy } from 'svelte'
    import { fade, fly } from 'svelte/transition'
    import type { Events, ModalData, TCloseModalFn } from './types'
    import ModalContent from './ModalContent.svelte'
    import merge from 'deepmerge'
    import { getString } from '@/misc/utilities'

    const loaderDelay = 100 // in ms, Loader nicht sofort zeigen, um visuelles Zuckeln auf schnellen Systemen zu vermeiden

    export let data: ModalData
    export let closeModal: TCloseModalFn
    export let updateRef
    export let events: Events

    const animationDuration = 300

    let modalElement: HTMLDivElement
    let bgElement: HTMLDivElement

    let showLoader = false
    let modalData: ModalData = data

    function handleBgClick(e) {
        if (e.target === bgElement) {
            e.stopPropagation() && e.preventDefault()

            close()
        }
    }

    function close() {
        closeModal(data.id)
    }

    async function defaultAsyncPropsFn(data: ModalData) {
        const response = await fetch(data.async.url, {
            method: data.async.body ? 'POST' : data.async.method || 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data.async.body ? JSON.stringify({
                ...data.async.body
            }) : null
        })

        if (data.type == 'html') {
            const html = await response.text()

            return {
                title: data.title,
                type: 'html',
                content: html
            }
        }
        else if (data.type == 'component') {
            const json = await response.json()

            if (json.errorcode) {
                return {
                    title: getString('error', 'moodle'),
                    type: 'html',
                    content: json.error
                }
            }

            return json
        }
    }

    async function handleCustomAsyncPropsFn(fn: () => Promise<Record<string, string|number>>) {
        try {
            return await fn()
        }
        catch(e) {
            throw new Error('Request failed: ' + e)
        }
    }

    onMount(async () => {
        updateRef(data.id, { close })
        data.onOpen && data.onOpen(modalElement)

        // Async Modals können auf zwei Weisen aufgerufen werden:
        // 1. Mit der Property 'async' als Objekt
        // 2. (deprecated) Mit async als Boolean zusammen mit asyncPropsFn
        if (data.async?.url || data.asyncPropsFn) {
            let asyncProps: Record<string, string|number>
            showLoader = !asyncProps

            // Loader nur mit Verzögerung anzeigen, damit der Bildschirm nicht zu
            // sehr zuckelt.
            setTimeout(() => {
                showLoader = !asyncProps
            }, loaderDelay)

            asyncProps = data.async?.url ?
                await defaultAsyncPropsFn(data) :
                await handleCustomAsyncPropsFn(data.asyncPropsFn)

            showLoader = false

            modalData = merge(data, asyncProps)
        }
    })

    onDestroy(() => {
        data.onClose && data.onClose(modalElement)
    })

    function getModalClasses() {
        const classes = [
            'modal',
            `modal--${data.type}`
        ]

        if (data.size) {
            classes.push(`modal-size--${data.size}`)
        }

        if (data.customClassName) {
            classes.push(data.customClassName)
        }

        return classes.join(' ')
    }
</script>

<div 
    bind:this={bgElement} 
    class="modal-wrapper"
    on:click={handleBgClick} 
    transition:fade={{ duration: animationDuration }}
>
    <div 
        bind:this={modalElement} 
        class={getModalClasses()} 
        transition:fly={{ y: 100, duration: animationDuration }} 
        role="dialog" 
        id={`modal-${data.id}`}
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                {#if showLoader}
                    <div class="loader">
                        <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="butt" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </div>
                {:else}
                    <ModalContent data={modalData} events={events} closeModal={closeModal} />
                {/if}
            </div>
        </div>

        {#if modalData.description}
            <div class="modal__description">
                {@html modalData.description}
            </div>
        {/if}
    </div>
</div>
