import { getString } from '@/misc/utilities';
export function bootstrapThemeUtils() {
    window.M.theme_soon = window.M.theme_soon || {};
    window.M.theme_soon.util = {
        load_strings: loadStrings,
        get_string: getString
    };
}
export async function loadStrings(strings) {
    const uncachedStrings = strings.filter(item => {
        var _a, _b;
        return (!((_b = (_a = window.M.str) === null || _a === void 0 ? void 0 : _a[item[1]]) === null || _b === void 0 ? void 0 : _b[item[0]]));
    });
    if (!uncachedStrings.length) {
        return;
    }
    const response = await fetch('/lib/ajax/service.php', {
        method: 'POST',
        body: JSON.stringify([{
                methodname: 'core_get_strings',
                index: 0,
                args: {
                    strings: strings.map(([stringid, component, a]) => ({
                        stringid, component, a
                    }))
                }
            }])
    });
    if (response.ok) {
        const json = await response.json();
        for (const { stringid, component, string } of json[0].data) {
            window.M.str[component] = window.M.str[component] || {};
            window.M.str[component][stringid] = string;
        }
    }
}
