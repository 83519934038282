<script lang="ts">
    import { onMount, onDestroy } from 'svelte'
    import type { Events, ModalDataElementContent } from '@/components/Modals/types'

    export const events: Events = null
    export let data: ModalDataElementContent

    let contentElement: HTMLElement
    let originalParent: Node

    onMount(() => {
        originalParent = data.content.parentNode
        contentElement.appendChild(data.content)
    })

    onDestroy(() => {
        if (originalParent) {
            originalParent.appendChild(data.content)
        }
    })
</script>

<div bind:this={contentElement} class="modal-content"></div>
