<script lang="ts">
  import type { Events, ModalDataComponent } from '@/components/Modals/types'
  import { createEventDispatcher, onMount } from 'svelte/internal'

  export const events: Events = null
  export let data: ModalDataComponent

  const dispatch = createEventDispatcher()

  const { content, props } = data
  const Content = content
  const closeModal = () => dispatch('closemodal')

  let modalBody: HTMLDivElement

  // https://github.com/sveltejs/svelte/issues/6584#issuecomment-1019578529
  onMount(() => {
    new Content({ target: modalBody, props: { ...props, closeModal }})
  })
</script>

<div bind:this={modalBody} class="modal-body"></div>
