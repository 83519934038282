import { configHyphenopoly } from '@/misc/hyphenopoly';
import { bootstrapEvents } from '@/bootstrap/events';
import { bootstrapModals } from '@/bootstrap/modals';
import { svelteExport } from 'svelteExport';
import { bootstrapThemeUtils } from '@/bootstrap/theme_utils';
window.M.theme_soon = window.M.theme_soon || {};
configHyphenopoly();
bootstrapEvents();
bootstrapModals();
bootstrapThemeUtils();
/**
  * @deprecated Wird durch SystemJS/ESM ersetzt und in nächster Version entfernt werden.
  */
svelteExport();
