import * as svelte from 'svelte';
import * as svelteAnimate from 'svelte/animate';
import * as svelteEasing from 'svelte/easing';
import * as svelteInternal from 'svelte/internal';
import * as svelteMotion from 'svelte/motion';
import * as svelteStore from 'svelte/store';
import * as svelteTransition from 'svelte/transition';
export function svelteExport() {
    window.__svelte = {};
    window.__svelte['svelte'] = svelte;
    window.__svelte['animate'] = svelteAnimate;
    window.__svelte['easing'] = svelteEasing;
    window.__svelte['internal'] = svelteInternal;
    window.__svelte['motion'] = svelteMotion;
    window.__svelte['store'] = svelteStore;
    window.__svelte['transition'] = svelteTransition;
}
