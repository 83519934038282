import ModalManager from '@/components/Modals/ModalManager.svelte';
export function bootstrapModals() {
    const modalContainer = document.createElement('div');
    modalContainer.id = 'modal-container';
    document.body.insertBefore(modalContainer, document.body.firstChild);
    new ModalManager({
        target: modalContainer,
        props: {
            events: window.M.theme_soon.events
        }
    });
}
